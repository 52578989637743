<!-- <div class="adjustmentCodes__header-text h2 h2--semi-bold">Roles</div> -->
<header class="sub-page-layout__header roles__header">
  <div class="roles__header-text h2 h2--semi-bold">Permission Roles</div>
  <button *ngIf="!error && !viewOnly" mat-flat-button color="primary" class="roles__add-item" (click)="addEditRole()">
    <mat-icon>add_circle_outline</mat-icon> Add Role
  </button>
</header>
<h4 class="empty-list" *ngIf="!error && !loading && !(rolesList.length > 0)">No Roles Found.</h4>
<div class="tableContainer mat-elevation-z2" *ngIf="!loading && rolesList.length > 0">
  <table mat-table [dataSource]="rolesList" class="dataSets__grid">
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <lib-overflow-menu [options]="overflowMenuOptions" (optionClicked)="overflowMenuClicked($event, element)">
        </lib-overflow-menu>
      </td>
    </ng-container>

    <ng-container matColumnDef="roleName">
      <th mat-header-cell *matHeaderCellDef>Role Name</th>
      <td mat-cell *matCellDef="let element">{{ element.roleName }}</td>
    </ng-container>

    <ng-container *ngFor="let column of permissionTypeList; trackBy: trackByIndex" [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon>{{
          element.updatePermissions.includes(column)
            ? 'mode_edit'
            : element.viewPermissions.includes(column)
            ? 'visibility'
            : 'cancel'
        }}</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div class="loading" *ngIf="loading">
  <mat-spinner [diameter]="150"></mat-spinner>
</div>
<div class="roles__error" *ngIf="error">
  {{ roleListError }}
</div>
<footer *ngIf="isImplementation && length > 0" class="roles__footer">
  <lib-button-loading color="primary" class="roles__button" [invalid]="length === 0" (clickEvent)="next()"
    >Next
  </lib-button-loading>
</footer>
