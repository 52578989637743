import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ComparisonType, FilterType } from '../../models';
import { FilterConfiguration } from '../../models';
import { inputEndSuffix, inputStartSuffix } from '../../constants';

@Component({
  selector: 'lib-grid-filter',
  templateUrl: './grid-filter.component.html',
  styleUrls: ['./grid-filter.component.scss']
})
export class GridFilterComponent implements OnInit {
  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  controlName: string;

  @Input()
  filterConfig: FilterConfiguration = {
    filterType: FilterType.textBox,
    comparisonType: ComparisonType.Contains
  };

  filterType = FilterType;

  filterControlStartName = '';
  filterControlEndName = '';

  constructor() {}

  ngOnInit(): void {
    switch (this.filterConfig?.filterType) {
      // Future Feature
      case this.filterType.number:
        this.filterControlStartName = `${this.controlName}${inputStartSuffix}`;
        this.filterControlEndName = `${this.controlName}${inputEndSuffix}`;
        let startValue = this.filterConfig?.initialStart !== -999999999 ? this.filterConfig?.initialStart : '';
        let endValue = this.filterConfig?.initialEnd !== 999999999 ? this.filterConfig?.initialEnd : '';

        this.formGroup.addControl(this.filterControlStartName, new UntypedFormControl(startValue));
        this.formGroup.addControl(this.filterControlEndName, new UntypedFormControl(endValue));
      case this.filterType.date:
        this.filterControlStartName = `${this.controlName}${inputStartSuffix}`;
        this.filterControlEndName = `${this.controlName}${inputEndSuffix}`;

        this.formGroup.addControl(this.filterControlStartName, new UntypedFormControl(this.filterConfig?.initialStart ? new Date(this.filterConfig?.initialStart) : ''));
        this.formGroup.addControl(this.filterControlEndName, new UntypedFormControl(this.filterConfig?.initialEnd ? new Date(this.filterConfig?.initialEnd) : ''));

        break;
      default:
        this.formGroup?.addControl(this.controlName, new UntypedFormControl(this.filterConfig?.initial ?? ''));
        break;
    }
  }

  trackByValue(index: number, item: any) {
    return item.value
  }
}
