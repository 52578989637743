/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface QueueResultDetailModel {
  visitId?: string;
  queue?: string | null;
  resultDescription?: string | null;
  isCustomQueue?: boolean;
  isScheduledWriteOff?: boolean;
  insuranceId?: string | null;
  responseId?: string | null;
  claimStatusId?: string | null;
  underpaymentId?: string | null;
  eobId?: string | null;
  arTransactionId?: string | null;
  iconType?: string | null;
  customQueueHoldId?: string | null;
  procedureName?: string | null;
  additionalDetails?: Array<string> | null;
}
