import { AfterViewInit, ChangeDetectorRef, Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomValidators, DialogContent, FormGroupDefinition, GridComponent } from 'components';
import { compare } from 'fast-json-patch';
import { Observable, of } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { InsuranceCarrierWrapperService } from 'src/app/core/services/service-wrappers/insurance-carrier-wrapper.service';
import { CountryCodesLookupService, FinancialClassesLookupService, InsuranceCarriersLookupService, InsuranceTypesLookupService, StateCodesLookupService } from 'src/app/core/services/lookup';
import { PaperHcfaDialogComponent } from './paper-hcfa-dialog/paper-hcfa-dialog.component';
import { EClaimsDialogComponent } from './e-claims-dialog/e-claims-dialog.component';
import { PlaceOfServiceDialogComponent } from './place-of-service-dialog/place-of-service-dialog.component';
import { FeeScheduleLookupService } from '../../../../core/services/lookup/fee-schedule-lookup.service';
import { AdjustmentCodesLookupService } from 'src/app/core/services/lookup/adjustment-codes-lookup.service';
import { PaymentCodesLookupService } from 'src/app/core/services/lookup/payment-codes-lookup.service';
import { MailingAddressUtility } from '../../../../core/utilities/function.utils';
import { DialogService } from '@core/services/dialog.service';

@Component({
  selector: 'app-insurance-carriers-dialog',
  templateUrl: './insurance-carriers-dialog.component.html',
  styleUrls: ['./insurance-carriers-dialog.component.scss']
})
export class InsuranceCarriersDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(GridComponent) grid: GridComponent;
  formGroup = new UntypedFormGroup({});
  formInitialized = false;
  saving = false;
  insuranceCarrierId;
  formData: any;
  mailingAddressUtility = new MailingAddressUtility;
  viewOnly = false;
  formDefinitions: FormGroupDefinition[];
  getFormDefinitions(isEdit: boolean): FormGroupDefinition[] {
    return [
      {
        hideTitle: true,
        controls: [
          {
            label: 'Carrier Code',
            name: 'carrierCode',
            isReadOnly: isEdit,
            type: 'text',
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'Carrier Name',
            name: 'carrierName',
            type: 'text',
            class: 'form-span-7',
            validators: Validators.required
          },
          {
            label: 'Active',
            name: 'active',
            type: 'checkbox',
            initial: true,
            class: 'form-span-2',
            validators: Validators.required
          },
          {
            label: 'Payer ID',
            name: 'payerId',
            type: 'text',
            class: 'form-span-6',
          },
          {
            label: 'Crossover Payer ID',
            name: 'crossoverPayerId',
            type: 'text',
            class: 'form-span-6'
          },
          {
            label: 'Charge Insurance Code',
            name: 'chargeInsCode',
            type: 'select',
            options:
              [{ label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
              { label: '4', value: 4 },
              { label: '5', value: 5 }],
            initial: 1,
            class: 'form-span-6',
            validators: Validators.required
          },
          {
            label: 'Claim Type',
            name: 'claimType',
            type: 'select',
            options:
              [{ label: 'Electronic', value: 'Electronic' },
              { label: 'Paper', value: 'Paper' }],
            initial: 'Electronic',
            class: 'form-span-6',
            validators: Validators.required
          },
          {
            label: '',
            type: 'label',
            class: 'form-span-12'
          },
          {
            label: 'Insurance Carrier Type',
            name: 'carrierType',
            type: 'select',
            options: ['PPO',
              'OtherNonFederal',
              'POS',
              'EPO',
              'Indemnity',
              'HmoMedicareRisk',
              'DMO',
              'AutoMedical',
              'BlueCrossBlueShield',
              'Champus',
              'Commercial',
              'Disability',
              'FederalEmployeeProgram',
              'HMO',
              'LiabilityMedical',
              'MedicarePartA',
              'MedicarePartB',
              'Medicaid',
              'OtherFederal',
              'TitleV',
              'VeteransAffairsPlan',
              'WorkersCompensation',
              'MutuallyDefined',
              'SelfPay',
              'GroupBilling'],
            class: 'form-span-6',
            validators: Validators.required
          },
          {
            label: 'Default Financial Class',
            name: 'financialClassId',
            type: 'autocomplete',
            apiService: this.financialCodeLookupService,
            class: 'form-span-6',
            validators: Validators.required
          },
          {
            label: 'Payment Fee Schedule',
            name: 'feeScheduleId',
            type: 'select',
            apiService: this.feeScheduleLookup,
            clearButton: true,
            class: 'form-span-6 defaults'
          },
          {
            label: 'Procedure Fee Schedule',
            name: 'procedureFeeScheduleId',
            type: 'select',
            apiService: this.feeScheduleLookup,
            clearButton: true,
            class: 'form-span-6 defaults'
          },
          {
            label: 'Default Payment Code',
            name: 'defaultPaymentCode',
            type: 'select',
            apiService: this.paymentCodesLookupService,
            clearButton: true,
            class: 'form-span-6 defaults'
          },
          {
            label: 'Default Adjustment Code',
            name: 'defaultAdjustmentCode',
            type: 'select',
            apiService: this.adjustmentCodesLookupService,
            clearButton: true,
            class: 'form-span-6 defaults'
          },
          {
            label: 'Default Adjustment Code 2',
            name: 'defaultAdjustmentCode2',
            type: 'select',
            apiService: this.adjustmentCodesLookupService,
            clearButton: true,
            class: 'form-span-6 defaults'
          },
          {
            label: 'Default Supplemental Insurance Type',
            name: 'defaultInsuranceTypeId',
            type: 'select',
            apiService: this.insuranceCarrierTypeLookup,
            class: 'form-span-6',
            clearButton: true
          },
          {
            label: 'Policy Number Required',
            name: 'policyNumberRequired',
            type: 'checkbox',
            initial: true,
            class: 'form-span-3'
          },
          {
            label: 'Group Number Required',
            name: 'groupNumberRequired',
            type: 'checkbox',
            initial: false,
            class: 'form-span-3'
          },
          {
            label: 'Referring Provider Required',
            name: 'referringProviderRequired',
            type: 'checkbox',
            initial: true,
            class: 'form-span-3'
          },
          {
            label: 'Accept Assignment',
            name: 'acceptAssignment',
            type: 'checkbox',
            initial: true,
            class: 'form-span-3'
          },
          {
            label: 'Dept of Labor Carrier',
            name: 'useDeptOfLaborProviderId',
            type: 'checkbox',
            initial: false,
            class: 'form-span-3'
          },
          {
            label: 'Exclude From Eligibility',
            name: 'excludeEligibility',
            type: 'checkbox',
            initial: false,
            class: 'form-span-3'
          },
          {
            label: 'Hold Billing',
            name: 'holdBilling',
            type: 'checkbox',
            initial: false,
            class: 'form-span-3'
          },
          {
            label: 'Create Auto-Adjustment',
            name: 'autoAdjustProcedures',
            type: 'checkbox',
            initial: false,
            class: 'form-span-3'
          },
          {
            label: 'Address',
            type: 'label',
            class: 'form-span-12'
          },
          {
            label: 'Address Line 1',
            name: 'address1',
            type: 'text',
            class: 'form-span-6',
            validators: Validators.required
          },
          {
            label: 'Address Line 2',
            name: 'address2',
            type: 'text',
            class: 'form-span-6'
          },
          {
            label: 'City',
            name: 'city',
            type: 'text',
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'State',
            name: 'stateCode',
            type: 'select',
            apiService: this.stateCodesLookupService,
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'Zip',
            name: 'zip',
            type: 'zipCode',
            class: 'form-span-3',
            validators: [Validators.required, CustomValidators.zipCodePatternValidator]
          },
          {
            label: 'Country',
            name: 'countryCode',
            type: 'select',
            apiService: this.countryService,
            initial: 'US',
            class: 'form-span-3',
            clearButton: true
          },
          {
            label: 'Phone Number',
            name: 'phoneNumber',
            type: 'phone',
            class: 'form-span-3'
          },
          {
            label: 'Email',
            name: 'email',
            type: 'text',
            class: 'form-span-9'
          },
          {
            label: 'Refund Address',
            type: 'label',
            class: 'form-span-12'
          },
          {
            label: 'Same as Mailing Address',
            name: 'sameRefund',
            type: 'checkbox',
            initial: false,
            class: 'form-span-12'
          },
          {
            label: 'Refund Name',
            name: 'refundName',
            type: 'text',
            class: 'form-span-12'
          },
          {
            label: 'Address Line 1',
            name: 'refundAddress1',
            type: 'text',
            class: 'form-span-6'
          },
          {
            label: 'Address Line 2',
            name: 'refundAddress2',
            type: 'text',
            class: 'form-span-6'
          },
          {
            label: 'City',
            name: 'refundCity',
            type: 'text',
            class: 'form-span-3'
          },
          {
            label: 'State',
            name: 'refundStateCode',
            type: 'select',
            apiService: this.stateCodesLookupService,
            class: 'form-span-3',
            clearButton: true
          },
          {
            label: 'Zip',
            name: 'refundZip',
            type: 'zipCode',
            class: 'form-span-3',
            validators: CustomValidators.zipCodePatternValidator
          },
          {
            label: 'Country',
            name: 'refundCountryCode',
            type: 'select',
            apiService: this.countryService,
            class: 'form-span-3',
            clearButton: true
          }
        ]
      }
    ];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<InsuranceCarriersDialogComponent>,
    private service: InsuranceCarrierWrapperService,
    private stateCodesLookupService: StateCodesLookupService,
    private dialog: MatDialog,
    private financialCodeLookupService: FinancialClassesLookupService,
    private insuranceCarrierTypeLookup: InsuranceTypesLookupService,
    private feeScheduleLookup: FeeScheduleLookupService,
    private adjustmentCodesLookupService: AdjustmentCodesLookupService,
    private paymentCodesLookupService: PaymentCodesLookupService,
    private countryService: CountryCodesLookupService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService
  ) {
    this.insuranceCarrierId = data?.insuranceCarrierId;
    const isEdit = this.insuranceCarrierId && this.insuranceCarrierId !== '';
    this.viewOnly = data?.viewOnly;
    this.formDefinitions = this.getFormDefinitions(isEdit);
  }
  ngAfterViewInit(): void {
    this.formGroup.get('carrierType').valueChanges.subscribe((carrier) => {
      var claimType = this.formGroup.get('claimType').value;
      if (carrier === 'GroupBilling' || carrier === 'SelfPay') {
        this.formGroup.get('excludeEligibility').patchValue(true);
      }
      if (this.isPayerIdRequired(claimType, carrier)) {
        this.formGroup.get('payerId').setValidators(Validators.required)
      } else {
        this.formGroup.get('payerId').setValidators(null)
      }
      this.formGroup.get('payerId').updateValueAndValidity();
      this.cdr.detectChanges();
    })
    this.formGroup.get('claimType').valueChanges.subscribe((type) => {
      var carrierType = this.formGroup.get('carrierType').value;
      if (this.isPayerIdRequired(type, carrierType)) {
        this.formGroup.get('payerId').setValidators(Validators.required)
      } else {
        this.formGroup.get('payerId').setValidators(null)
      }
      this.formGroup.get('payerId').updateValueAndValidity();
      this.cdr.detectChanges();
    })
  }
  isPayerIdRequired(claimType, carrierType) {
    if (carrierType === 'GroupBilling' || carrierType === 'SelfPay' || claimType === 'Paper') {
      return false;
    }
    else {
      return true;
    }
  }

  ngOnInit(): void {
    this.setupForm();

    if (this.insuranceCarrierId && this.insuranceCarrierId !== '') {
      this.service
        .apiV1InsuranceCarrierIdGet(this.insuranceCarrierId)
        .pipe(first())
        .subscribe((result) => {
          this.paymentCodesLookupService.existingValues.push(result.paymentCodeId);
          this.adjustmentCodesLookupService.existingValues.push(result.adjustmentCodeId);
          this.adjustmentCodesLookupService.existingValues.push(result.adjustmentCode2Id);
          this.formGroup.get('defaultPaymentCode').patchValue(result.paymentCodeId);
          this.formGroup.get('defaultAdjustmentCode').patchValue(result.adjustmentCodeId);
          this.formGroup.get('defaultAdjustmentCode2').patchValue(result.adjustmentCode2Id);
          this.formGroup.patchValue(result);
          this.formGroup.get('active').patchValue(!result.inactive);
        });
    }
  }

  setupForm() {
    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });

    this.setFormSubscriptions();

    this.formInitialized = true;
  }

  setFormSubscriptions() {

    this.formGroup.get('sameRefund').valueChanges.subscribe((sameAs) => {
      this.mailingAddressUtility.subSameAsFunctionalityToSecondAddress(this.formGroup, sameAs, 'refund');
    })

  }

  preCheck() {

    if (this.insuranceCarrierId) {
      this.save();
    } else {
      this.service.apiV1InsuranceCarrierValidateCarrierCodePost(this.formGroup.get('carrierCode').value).pipe(map((x) => x))
        .subscribe((data) => {
          if (data == null) { this.save(); }
          else this.dupCode();
        })
    }
  }

  dupCode() {
    let notification = 'Insurance Carrier with code ' + this.formGroup.get('carrierCode').value + ' already exists';
    this.notificationService.error(notification);
    this.saving = false;
  }

  save() {
    if (this.formGroup.valid && this.saving === false) {
      this.saving = true;
      this.formData = {
        inactive: this.formGroup.get('active').value === true ? false : true,
        carrierName: this.formGroup.get('carrierName')?.value,
        carrierCode: this.formGroup.get('carrierCode').value,
        address1: this.formGroup.get('address1').value,
        address2: this.formGroup.get('address2').value,
        city: this.formGroup.get('city').value,
        stateCode: this.formGroup.get('stateCode').value,
        zip: this.formGroup.get('zip').value,
        crossoverPayerId: this.formGroup.get('crossoverPayerId').value,
        payerId: this.formGroup.get('payerId').value,
        countryCode: this.formGroup.get('countryCode').value,
        phoneNumber: this.formGroup.get('phoneNumber').value,
        holdBilling: this.formGroup.get('holdBilling').value,
        referringProviderRequired: this.formGroup.get('referringProviderRequired').value,
        policyNumberRequired: this.formGroup.get('policyNumberRequired').value,
        groupNumberRequired: this.formGroup.get('groupNumberRequired').value,
        carrierType: this.formGroup.get('carrierType').value,
        chargeInsCode: this.formGroup.get('chargeInsCode').value,
        financialClassId: this.formGroup.get('financialClassId').value,
        feeScheduleId: this.formGroup.get('feeScheduleId').value,
        procedureFeeScheduleId: this.formGroup.get('procedureFeeScheduleId').value,
        acceptAssignment: this.formGroup.get('acceptAssignment').value,
        sameRefund: this.formGroup.get('sameRefund').value,
        refundName: this.formGroup.get('carrierName').value,
        refundAddress1: this.formGroup.get('refundAddress1').value,
        refundAddress2: this.formGroup.get('refundAddress2').value,
        refundCity: this.formGroup.get('refundCity').value,
        refundStateCode: this.formGroup.get('refundStateCode').value,
        refundZip: this.formGroup.get('zip').value,
        refundCountryCode: this.formGroup.get('refundCountryCode').value,
        useDeptOfLaborProviderId: this.formGroup.get('useDeptOfLaborProviderId').value,
        email: this.formGroup.get('email').value,
        paymentCodeId: this.formGroup.get('defaultPaymentCode').value,
        //interestCodeId: this.formGroup.get('defaultInterestCode').value,
        adjustmentCodeId: this.formGroup.get('defaultAdjustmentCode').value,
        adjustmentCode2Id: this.formGroup.get('defaultAdjustmentCode2').value,
        defaultInsuranceTypeId: this.formGroup.get('defaultInsuranceTypeId').value,
        excludeEligibility: this.formGroup.get('excludeEligibility').value,
        autoAdjustProcedures: this.formGroup.get('autoAdjustProcedures').value,
        claimType: this.formGroup.get('claimType').value
      };
  
      let saveObservable: Observable<any>;
      let notification;
      if (this.formData.autoAdjustProcedures === true && !this.formData.adjustmentCodeId) {
        const modalContent: DialogContent = {
          header: 'Visit Information',
          body: "'Default Adjustment Code' is required if 'Create Auto-Adjustment' is selected.",
          OKButtonText: 'OK',
          cancelButtonVisible: false
        };
        this.dialogService.showConfirm(modalContent);
        this.saving = false;
        return;
      } else if (this.formData.autoAdjustProcedures === true && !this.formData.feeScheduleId) {
        const modalContent: DialogContent = {
          header: 'Visit Information',
          body: "'Payment Fee Schedule' is required if 'Create Auto-Adjustment' is selected.",
          OKButtonText: 'OK',
          cancelButtonVisible: false
        };
        this.dialogService.showConfirm(modalContent);
        this.saving = false;
        return;
      } else if (this.insuranceCarrierId) {
        notification = 'Insurance Carrier Updated';
        saveObservable = this.service
          .apiV1InsuranceCarrierPatchIdPatch(this.insuranceCarrierId, compare({}, this.formData))
          .pipe(map((x: any) => x));
      } else {
        notification = 'Insurance Carrier Added';
        saveObservable = this.service.apiV1InsuranceCarrierAddPost(this.formData).pipe(map((x: any) => x));
      }
  
      saveObservable
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.dialogRef.close(true);
            this.notificationService.success(notification);
          },
          (err) => this.notificationService.error('Error Saving Insurance Carrier')
        )
        .add(() => {
          this.saving = false;
        });
    }
  }

  addHCFA() {
    this.openHCFAModal(this.insuranceCarrierId);
  }

  openHCFAModal(insuranceCarrierId: string) {
    const dialog = this.dialog.open(PaperHcfaDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { insuranceCarrierId, viewOnly: this.viewOnly }
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        //this.grid.loadData();
      }
    });
  }

  addEClaim() {
    this.openEClaimModal(null);
  }

  openEClaimModal(insuranceCarrierId: string) {
    const dialog = this.dialog.open(EClaimsDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { insuranceCarrierId }
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        /*this.grid.loadData();*/
      }
    });
  }

  addCrosswalk() {
    this.openCrosswalkModal(this.insuranceCarrierId);
  }

  openCrosswalkModal(insuranceCarrierId: string) {
    const dialog = this.dialog.open(PlaceOfServiceDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { insuranceCarrierId, viewOnly: this.viewOnly }
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        /*this.grid.loadData();*/
      }
    });
  }
}


