import { AfterViewInit, ChangeDetectorRef, Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomValidators, FormGroupDefinition } from 'components';
import { compare } from 'fast-json-patch';
import { Observable } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DialogService } from '../../../../core/services/dialog.service';
import { EobWrapperService } from 'src/app/core/services/service-wrappers/eob-wrapper.service';
import { PatientLookupService } from 'src/app/core/services/lookup/patient-lookup.service';
import { StatementMessageLookupService } from 'src/app/core/services/lookup/statement-message-lookup.service';
import { DatePipe } from '@angular/common';
import { EobCodesLookupService } from '../../../../core/services/lookup/eob-codes-lookup.service';
import { AdjustmentCodesLookupService } from '@core/services/lookup/adjustment-codes-lookup.service';

@Component({
  selector: 'app-eob-codes-dialog',
  templateUrl: './eob-codes-dialog.component.html',
  styleUrls: ['./eob-codes-dialog.component.scss'],
  providers: [DatePipe]
})
export class EobCodesDialogComponent implements OnInit, AfterViewInit {
  formGroup = new UntypedFormGroup({});
  formInitialized = false;
  saving = false;
  sameCode = false;
  duplicateCode = false;
  eobCodeId;
  localResults;
  duplicateResponse = '';
  viewOnly = false;

  formDefinitions: FormGroupDefinition[];
  getFormDefinitions(isEdit: boolean): FormGroupDefinition[] {
    return [
      {
        hideTitle: true,
        controls: [
          {
            label: 'Code',
            name: 'code',
            isReadOnly: isEdit,
            type: 'text',
            class: 'form-span-3',
            validators: Validators.required,
            selectionChanged: (event) => {
              this.nameChange(event);
            }
          },
          {
            label: 'Description',
            name: 'name',
            type: 'text',
            class: 'form-span-8',
            validators: Validators.required
          },
          {
            label: 'Active',
            name: 'active',
            type: 'checkbox',
            class: 'form-span-1',
            initial: true
          },
          {
            label: 'Write Off Procedure',
            name: 'doWriteOff',
            type: 'select',
            options: ['Yes', 'No', {label: 'EOB Amount', value:'EOB_Amount'}],
            class: 'form-span-6',
            validators: Validators.required
          },
          {
            label: 'Write Off Adjustment Code',
            name: 'adjustmentCodeId',
            type: 'select',
            apiService: this.adjustmentCodesLookupService,
            class: 'form-span-6',
            clearButton: true
          },
          {
            label: 'Bill Next',
            name: 'billNext',
            type: 'select',
            options: ['Yes', 'No'],
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'Move To Patient Responsible',
            name: 'patientResponsible',
            type: 'select',
            options: [{ label: 'Yes', value: true }, { label:'No', value:false}],
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'Statement Message',
            name: 'statementMessage',
            type: 'text',
            class: 'form-span-6'
          },
          {
            label: 'Suppress Secondary',
            name: 'suppressSecondary',
            type: 'checkbox',
            class: 'form-span-3',
            initial: false
          },
          {
            label: 'Denied',
            name: 'isDenied',
            type: 'checkbox',
            class: 'form-span-3',
            initial: false
          },
          {
            label: 'Underpaid',
            name: 'isUnderPaid',
            type: 'checkbox',
            class: 'form-span-3',
            initial: false
          },
          {
            label: 'Follow Up',
            name: 'isFollowUp',
            type: 'checkbox',
            class: 'form-span-2',
            initial: false
          },
          {
            label: 'Follow Up Days',
            name: 'followUpDays',
            type: 'text',
            class: 'form-span-1',
            validators: [CustomValidators.numberValidator]
          },
        ]
      },
    ];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<EobCodesDialogComponent>,
    private service: EobWrapperService,
    private dialogService: DialogService,
    public lookup: EobCodesLookupService,
    public datePipe: DatePipe,
    public dialog: MatDialog,
    private patientLookupService: PatientLookupService,
    private adjustmentCodesLookupService: AdjustmentCodesLookupService,
    private statementMessageLookupService: StatementMessageLookupService,
    private cd: ChangeDetectorRef,

  ) {
    this.eobCodeId = data?.eobCodeId;
    this.viewOnly = data?.viewOnly;
    const isEdit = this.eobCodeId && this.eobCodeId !== '';
    this.formDefinitions = this.getFormDefinitions(isEdit);
  }
  
  ngOnInit(): void {
    if (this.eobCodeId && this.eobCodeId !== '') {
      this.service
        .apiV1EobcodeIdGet(this.eobCodeId)
        .pipe(first())
        .subscribe((result) => {
          if (result.adjustmentCodeId != null) {
            this.adjustmentCodesLookupService?.existingValues?.push(result.adjustmentCodeId);
          }
          this.setupForm();  
          this.formGroup.patchValue(result);
          if (result.inactive === true) {
            this.formGroup.get('active').setValue(false)
          }
          if (result.inactive === false) {
            this.formGroup.get('active').setValue(true)
          }
          if (result.isDenied === "No") {
            this.formGroup.get('isDenied').setValue(false);
          }
          if(result.isDenied === "Yes") {
            this.formGroup.get('isDenied').setValue(true);
          }
          let input = this.formGroup.get('doWriteOff');
          let output = this.formGroup.get('adjustmentCodeId');
          if (input.value !== 'No') {
            output.setValidators([Validators.required]);
            output.updateValueAndValidity();
            if (this.formGroup.invalid) {
              this.saving = false
            }
          }
        });
    }
    else {
      this.setupForm();
    }
  }
  ngAfterViewInit(): void {
    this.adjustmentCodeValidator();
    this.cd.detectChanges();  
  }
  setupForm() {
    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });
    this.formInitialized = true;
  }

  //throws error if code already exists
  nameChange(event) {
    if (event !== '') {
      //searches lookup service to query backend for current user name field input this is not exact match searhc so it brings back results that include the current user field input
      this.lookup.searchEOBmaint(event).subscribe((result: any[]) => {
        this.localResults = result;
        //this result.find searches the results array from the lookup search to see if theres an exact match to the current user input
        let duplicate = result.find((o) => {
          return (o.value?.code.toLowerCase() === event.toLowerCase() && this.eobCodeId !== o.value.id)
        })
        if (duplicate !== undefined) {
          //sets same as code variable to true and then checks the date varibales with the sameNameTo
          this.sameCode = true;
          this.duplicateCode = true;
          this.duplicateResponse = 'This EOB Code already exists.';
        }
        else {
          this.sameCode = false;
          this.duplicateCode = false;
        }
      });
    }
  }

  save() {
    if (this.formGroup.valid && this.saving === false) {
      this.saving = true;

      const formData = {
        code: this.formGroup?.get('code')?.value,
        name: this.formGroup?.get('name')?.value,
        inactive: this.formGroup?.get('active')?.value === true ? false : true,
        doWriteOff: this.formGroup?.get('doWriteOff')?.value,
        adjustmentCodeId: this.formGroup?.get('adjustmentCodeId')?.value,
        statementMessage: this.formGroup?.get('statementMessage')?.value,
        billNext: this.formGroup?.get('billNext')?.value,
        patientResponsible: this.formGroup?.get('patientResponsible')?.value,
        isDenied: this.formGroup?.get('isDenied')?.value === true ? 'Yes' : 'No',
        isFollowUp: this.formGroup?.get('isFollowUp')?.value,
        followUpDays: this.formGroup?.get('followUpDays')?.value ? this.formGroup?.get('followUpDays')?.value : "0",
        suppressSecondary: this.formGroup?.get('suppressSecondary')?.value,
        isUnderPaid: this.formGroup?.get('isUnderPaid')?.value
      };
      
      let saveObservable: Observable<any>;
      let notification;
      if (this.eobCodeId) {
        notification = 'EOB Code Updated';
        saveObservable = this.service
          .apiV1EobCodePatchIdPath(this.eobCodeId, compare({}, formData))
          .pipe(map((x: any) => x));
      } else {
        notification = 'EOB Code Added';
        saveObservable = this.service.apiV1EobCodeAddPost(formData).pipe(map((x: any) => x));
      }

      saveObservable
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.dialogRef.close(true);
            this.notificationService.success(notification);
          },
          (err) => this.notificationService.error('Error Saving EOB Code')
        )
        .add(() => {
          this.saving = false;
        });
    }
  }

  adjustmentCodeValidator() {
    let input = this.formGroup.get('doWriteOff');
    let output = this.formGroup.get('adjustmentCodeId');
    input?.valueChanges?.subscribe((writeOffCharge) => {
      if (writeOffCharge !== 'No') {
        output.setValidators([Validators.required]);
        output.updateValueAndValidity();
        if (this.formGroup.invalid) {
          this.saving = false
        }
      } else {
        output.removeValidators([Validators.required])
        output.updateValueAndValidity();
        if (this.formGroup.invalid) {
          this.saving = false
        }
      }  
    });
  }
}
