<div class="dialog-form add-edit-role-form">
  <div class="dialog-form__close">
    <a mat-dialog-close>
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <header class="dialog-form__title">
    <h2>{{ roleId ? 'Edit' : 'Add' }} Role</h2>
  </header>
  <main>
    <div class="dialog-form__container">
      <form [formGroup]="formGroup">
        <lib-form-field class="field" [parent]="formGroup" label="Role Name" name="roleName" type="text">
        </lib-form-field>
        <lib-form-field class="field" [parent]="formGroup" label="Description" name="description" type="text">
        </lib-form-field>
        <div>
          <h3>Permissions</h3>
          <div class="roles" *ngFor="let permission of permissionsArray; trackBy: trackByIndex">
            <div class="radio-label">{{ permission.label }}</div>
            <mat-radio-group [formControlName]="permission.name">
              <mat-radio-button class="radio-button" value="None">None</mat-radio-button>
              <mat-radio-button class="radio-button" value="View">View</mat-radio-button>
              <mat-radio-button class="radio-button" value="Update">Update</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </form>
    </div>
  </main>
  <div mat-dialog-actions class="buttons">
    <button *ngIf="viewOnly === true" class="close-button" mat-stroked-button mat-dialog-close>Close</button>
    <button class="cancel-button" mat-stroked-button mat-dialog-close>Cancel</button>
    <lib-button-loading
      color="primary"
      class="save-button"
      [loading]="saving"
      [invalid]="formGroup.invalid"
      (clickEvent)="save()"
      >Save
    </lib-button-loading>
  </div>
</div>
