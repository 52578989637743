import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { ReportWrapperService } from '../../../../core/services/service-wrappers/report-wrapper.service';
import { BodyClasses, ColumnSizes, FieldSelectOption, FilterType } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { Store } from '@ngrx/store';
import { ImportedChargesReportService } from './imported-charges-report.service';
import {
  InsuranceCarriersLookupService,
  FinancialClassesLookupService,
  ProcedureCodesLookupService,
  DiagnosisCodesLookupService,
} from 'src/app/core/services/lookup';

export const ImportedChargesReport: Report = {
  id: 'imported-charges-report',
  label: 'Imported Procedures Report',
  textCriteria: {
    'Range Type': 'U', 'Start Date': '', 'End Date': '',
    'Include / Exclude Insurance Carriers': 'I', 'Insurance Carriers': '', 'Include / Exclude Financial Classes': 'I', 'Financial Classes': '',
    'Posted / Non - Posted': 'P'
  },
  cleanFilterParams: {
    'insuranceCarrierIds': '', 'financialClassIds': ''
  },
  getReportTitle: () => {
    return ImportedChargesReport.label + ' ' + ImportedChargesReport.textCriteria['Start Date'] + ' - ' + ImportedChargesReport.textCriteria['End Date']
      + ' (' + ImportedChargesReport.textCriteria['Date Range Type'] + ')';
  },
  getSearchCriteria: (injector: Injector) => [
    {
      title: '',
      controls: [
        {
          label: '',
          class: 'form-span-2',
          type: 'label'
        },
        {
          label: 'Range Type',
          name: 'dateRangeType',
          type: 'select',
          class: 'form-span-3',
          options: [
            {
              label: 'Service Date',
              value: 'S'
            },
            {
              label: 'Posting Date',
              value: 'P'
            },
            {
              label: 'Upload Date',
              value: 'U'
            }
          ],
          initial: 'U',
          validators: Validators.required,
          selectionChanged: (event) => {
            ImportedChargesReport.textCriteria['Date Range Type'] = event.source.triggerValue;
          }
        },
        {
          label: 'Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-5',
          daterangeStartChanged: (event) => {
            ImportedChargesReport.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            ImportedChargesReport.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: 'Posted / Non-Posted',
          name: 'postedNonPosted',
          class: 'form-span-2',
          type: 'select',

          options: [
            {
              label: 'Posted',
              value: 'P'
            },
            {
              label: 'NonPosted',
              value: 'N'
            }
          ],
          initial: 'N',
          validators: Validators.required,
          selectionChanged: (event) => {
            ImportedChargesReport.textCriteria['Posted / Non-Posted'] = event.source.triggerValue;
          }
        },
        {
          label: 'Exclude:',
          name: 'includeExcludeInsuranceCarriers',
          class: 'form-span-2 toggle',
          type: 'toggle',
          checkedText: 'Include:',
          initial: true,
          toggleChanged: (event) => {
            ImportedChargesReport.textCriteria['Include / Exclude Insurance Carriers'] = event.checked === true ? 'I' :'E'
         }
        },
        {
          matLabel: 'Primary Insurance Carriers',
          name: 'insuranceCarrierIds',
          apiService: injector.get(InsuranceCarriersLookupService),
          class: 'form-span-10 multi-input',
          type: 'multiSelect',
          placeholder: 'Insurance Carriers',
          selectOptions: (event: []) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            ImportedChargesReport.textCriteria['Insurance Carriers'] = listText.join(',');
            ImportedChargesReport.cleanFilterParams['insuranceCarrierIds'] = listValue
          },
        },
        {
          label: 'Exclude:',
          name: 'includeExcludeFinancialClasses',
          class: 'form-span-2 toggle',
          type: 'toggle',
          checkedText: 'Include:',
          initial: true,
          toggleChanged: (event) => {
            ImportedChargesReport.textCriteria['Include / Exclude Financial Classes'] = event.checked === true ? 'I' :'E'
         }
        },
        {
          matLabel: 'Financial Class',
          name: 'financialClassIds',
          apiService: injector.get(FinancialClassesLookupService),
          class: 'form-span-10 multi-input',
          type: 'multiSelect',
          placeholder: 'Financial Class',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            ImportedChargesReport.textCriteria['Financial Classes'] = listText.join(',');
            ImportedChargesReport.cleanFilterParams['financialClassIds'] = listValue
          }
        },
        {
          label: 'Exclude:',
          name: 'includeExcludeProcedures',
          class: 'form-span-2 toggle',
          type: 'toggle',
          checkedText: 'Include:',
          initial: true,
          toggleChanged: (event) => {
            ImportedChargesReport.textCriteria['Include / Exclude Procedures'] = event.checked === true ? 'I' :'E'
         }
        },
        {
          label: 'Procedure Codes',
          name: 'procedureIds',
          apiService: injector.get(ProcedureCodesLookupService),
          class: 'form-span-10 multi-input',
          type: 'multiSelect',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            ImportedChargesReport.textCriteria['Procedure Codes'] = listText.join(',');
            ImportedChargesReport.cleanFilterParams['procedureIds'] = listValue
          }
        },
        {
          label: 'Exclude:',
          name: 'includeExcludeDiagnosis',
          class: 'form-span-2 toggle',
          type: 'toggle',
          checkedText: 'Include:',
          initial: true,
          toggleChanged: (event) => {
            ImportedChargesReport.textCriteria['Include / Exclude Diagnosis Codes'] = event.checked === true ? 'I' : 'E'
          }
        },
        {
          label: 'Diagnosis Codes',
          name: 'diagnosisIds',
          apiService: injector.get(DiagnosisCodesLookupService),
          excludeSelectAllOption: true,
          class: 'form-span-10 multi-input',
          type: 'multiSelect',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            ImportedChargesReport.textCriteria['Diagnosis Codes'] = listText.join(',')
            ImportedChargesReport.cleanFilterParams['diagnosisIds'] = listValue
          }
        },
        {
          label: '',
          class: 'form-span-2',
          type: 'label'
        },
        {
          label: 'Quantity:',
          class: 'form-span-2',
          type: 'label'
        },
        {
          label: '>,<,or =',
          name: 'greaterThanOrLessThan',
          class: 'form-span-2',
          type: 'select',

          options: [
            {
              label: '',
              value: null
            },
            {
              label: '>',
              value: 'G'
            },
            {
              label: '<',
              value: 'L'
            },
            {
              label: '=',
              value: 'E'
            }
          ],
          initial: '',
          selectionChanged: (event) => {
            ImportedChargesReport.textCriteria['Greater Than Or Less'] = event.source.triggerValue;
          }
        },
        {
          label: 'Quantity',
          name: 'quantity',
          class: 'form-span-3',
          type: 'number',
          selectionChanged: (event) => {
            ImportedChargesReport.textCriteria['Quantity'] = event;
          }
        }
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const datePipe: DateDisplayPipe = new DateDisplayPipe(locale);
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(ImportedChargesReportService),
      columnDefinitions: [
        {
          id: 'Account Number',
          displayKey: 'Account Number',
          headerText: 'Account Number',
          displayOrder: 0,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Last Name',
          displayKey: 'Last Name',
          headerText: 'Last Name',
          displayOrder: 1,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'First Name',
          displayKey: 'First Name',
          headerText: 'First Name',
          displayOrder: 2,
          columnSize: ColumnSizes.extraWide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Visit Number',
          displayKey: 'Visit Number',
          headerText: 'Visit Number',
          displayOrder: 3,
          columnSize: ColumnSizes.extraNarrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Primary Insurance',
          displayKey: 'Primary Insurance',
          headerText: 'Primary Insurance',
          displayOrder: 4,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Primary Policy Number',
          displayKey: 'Primary Policy Number',
          headerText: 'Primary Policy Number',
          displayOrder: 5,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Secondary Insurance',
          displayKey: 'Secondary Insurance',
          headerText: 'Secondary Insurance',
          displayOrder: 6,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Secondary Policy Number',
          displayKey: 'Secondary Policy Number',
          headerText: 'Secondary Policy Number',
          displayOrder: 7,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Tertiary Insurance',
          displayKey: 'Tertiary Insurance',
          headerText: 'Tertiary Insurance',
          displayOrder: 8,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Tertiary Policy Number',
          displayKey: 'Tertiary Policy Number',
          headerText: 'Tertiary Policy Number',
          displayOrder: 9,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Financial Class',
          displayKey: 'Financial Class',
          headerText: 'Financial Class',
          displayOrder: 10,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Upload Date',
          displayKey: 'Upload Date',
          headerText: 'Upload Date',
          displayOrder: 11,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
        },
        {
          id: 'Service Date',
          displayKey: 'Service Date',
          headerText: 'Service Date',
          displayOrder: 12,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Posting Date',
          displayKey: 'Posting Date',
          headerText: 'Posting Date',
          displayOrder: 13,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
        }
      ]
    };
  }
}
