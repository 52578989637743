import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  BodyClasses,
  ColumnDefinition,
  ColumnDisplayType,
  ColumnSizes,
  GridComponent,
  GridConfiguration,
  OrderByPipe,
  DialogContent,
  FilterType
} from 'components';
import { map, take } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog.service';
import { ModifiersDialogComponent } from './modifiers-dialog/modifiers-dialog.component';
import { ModifiersService } from './modifiers.service';
import { CodeWrapperService } from 'src/app/core/services/service-wrappers/code-wrapper.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ImplementationWrapperService } from '@core/services/service-wrappers/implementation-wrapper.service';
import { Store } from '@ngrx/store';
import { ImplementationState } from '../../implementation/state/implementation.state';
import { Observable, Subscription } from 'rxjs';
import { selectImplementation } from '../../implementation/state/implementation.selectors';
import * as ImplementationActions from '../../implementation/state/implementation.actions';
import { ImplementationViewModel } from 'projects/data/src/lib/model/implementationViewModel';
import { ImplementationDetailViewModel } from 'projects/data/src/lib/model/implementationDetailViewModel';
import { UploadCodesComponent } from '../upload-codes/upload-codes.component';
import { SessionStorageService } from '@core/services/session-storage.service';
import { UserDetailsViewModel } from 'data';


@Component({
  selector: 'app-modifiers',
  templateUrl: './modifiers.component.html',
  styleUrls: ['./modifiers.component.scss'],
  providers: [ModifiersService]
})
export class ModifiersComponent implements OnInit, OnDestroy {
  @ViewChild(GridComponent) grid: GridComponent;
  orderPipe: OrderByPipe = new OrderByPipe();
  activeFilter: string = 'active';
  userInfo: UserDetailsViewModel;
  viewOnly = false;
  viewOnlyOptions = [{ id: 'view', label: 'View' }];
  overflowMenuOptions = [
    {
      id: 'edit',
      label: 'Edit'
    }
  ];
  private columnDefinitions: ColumnDefinition[] = [
    {
      id: 'actions',
      displayKey: 'actions',
      displayType: ColumnDisplayType.actionList,
      columnSize: ColumnSizes.fixedTiny,
      bodyClasses: [BodyClasses.alignRight],
      displayOrder: 0,
      headerText: '',
      overflowMenuOptions: this.overflowMenuOptions,
      overflowMenuClicked: (value, data) => {
        if (value === 'delete') {
          this.deleteEntry(data);
        } else if (value === 'edit') {
          this.openModifierModal(data.id);
        } else if (value === 'view') {
          this.openModifierModal(data.id, true);
        }
      }
    },
    {
      id: 'code',
      displayKey: 'code',
      headerText: 'Code',
      displayOrder: 1,
      columnSize: ColumnSizes.fixedColumn,
      filterable: true,
      sortable: true,
      sticky: true
    },
    {
      id: 'description',
      displayKey: 'name',
      headerText: 'Description',
      columnSize: ColumnSizes.superWide,
      displayOrder: 2,
      sortable: true,
      filterable: true
    }
  ];
  gridConfig: GridConfiguration = {
    columnDefinitions: this.columnDefinitions,
    displayColumns: this.orderPipe.transform(this.columnDefinitions, 'displayOrder').map((x) => x.id)
  };

  dataSource: ModifiersService;
  constructor(
    private modifierService: ModifiersService,
    public dialog: MatDialog,
    private deleteService: CodeWrapperService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private implementationService: ImplementationWrapperService,
    private router: Router,
    private store: Store<ImplementationState>,
    private sessionStorageService: SessionStorageService
  ) {
    this.userInfo = this.sessionStorageService.getItem("user-info");
    this.viewOnly = this.userInfo?.userPermissions?.find(x => x.permissionType == "SystemMaintenance")?.accessType !== "Update";
    if (this.viewOnly) {
      this.columnDefinitions[0].overflowMenuOptions = this.viewOnlyOptions;
    }
    this.dataSource = modifierService;
    this.dataSource.activeFilter = 'active';
  }

  addModifiers() {
    this.openModifierModal(null);
  }

  openModifierModal(modifierId: string, viewOnly = false) {
    const dialog = this.dialog.open(ModifiersDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { modifierId, viewOnly }
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.grid.loadData();
      }
    });
  }

  deleteEntry(data) {
    const modalContent: DialogContent = {
      header: 'Delete Modifier',
      body: `Are you sure you want to delete "${data.code} - ${data.name}"? This cannot be undone.`,
      cancelButtonText: 'Cancel',
      OKButtonText: 'Delete'
    };
    this.dialogService.showConfirm(modalContent).subscribe((result) => {
      if (result) {
        this.deleteService
          .apiV1CodeCodetypeDeleteIdDelete('Modifier', data.id)
          .pipe(
            map((x: any) => x),
            take(1)
          )
          .subscribe((deleteResult) => {
            this.grid.loadData();
            this.notificationService.notifyNow("Modifier Deleted");
          });
      }
    });
  }

  uploadCodes() {
    const dialog = this.dialog.open(UploadCodesComponent, {
      disableClose: true,
      autoFocus: false,
      height: '600px',
      width:'600px',
      data: {codeType: 'Modifier'}
    });

    dialog.afterClosed().subscribe((result) => {
        this.grid.loadData();
    });
  }

  public isImplementation: boolean = false;
  public gonext: boolean = false;
  public itemCount: number = 0;
  private activatedRouteSub: Subscription;
  private itemCountSub: Subscription;
  private implementation$: Observable<ImplementationViewModel> = this.store.select(selectImplementation);;
  private implementationSub: Subscription;
  private implementation : ImplementationViewModel = null;
  private implementationDetail: ImplementationDetailViewModel = null;
  private nextImplementationDetail: ImplementationDetailViewModel = null;
  private STEP_CODE = 'modifiers'

  implementationSubscribe() {
    this.activatedRouteSub = this.activatedRoute.data?.subscribe(data => {
      this.isImplementation = data.implementation;

      if (this.isImplementation) {
        this.itemCountSub = this.modifierService.itemCountSubject.subscribe((c) => this.itemCount = c);
        this.implementationSub =
          this.implementation$.subscribe((i) => {
            this.implementation = i;
            this.implementationDetail = this.implementation.details.find(d => d.implementationStepCode == this.STEP_CODE);
            this.nextImplementationDetail = this.implementation.details.find(d => d.order == this.implementationDetail.order + 1);
        });
      }
    });
    this.store.dispatch(ImplementationActions.updateImplementationStep({ stepCode: this.STEP_CODE}));
  }

  implementationUnsubscribe() {
    if (this.isImplementation) {
      this.activatedRouteSub.unsubscribe();
      this.itemCountSub.unsubscribe();
      this.implementationSub.unsubscribe();
    }
  }

  next(): void {
    if (this.implementationDetail.status == "InProgress") {
      this.gonext = true;
      this.implementationService
      .apiV1ImplementationCompleteStepCodePost(this.STEP_CODE)
      .subscribe(
        () => {
          this.gonext = false;
          this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
        },
        () => this.gonext = false
      );
    }
    else {
      this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
    }
  }

  ngOnInit(): void {
    this.implementationSubscribe();
  }
  ngOnDestroy(): void {
    this.implementationUnsubscribe();
  }
  showInactiveChanges(event) {
    this.activeFilter = event.checked ? 'active' : 'inactive';
    this.dataSource.activeFilter = this.activeFilter;
    this.grid.loadData();
  }
}
