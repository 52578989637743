<div class="dialog-form report-form">
  <header mat-dialog-title class="dialog-form__title">
    <h2>Fee Schedule Entry</h2>
  </header>
  <main mat-dialog-content>
    <div class="dialog-form__container">
      <lib-form-group [formGroup]="entryForm" class="entry" stackedTitle="true" hideTitle="true">
        <lib-form-field
          [parent]="entryForm"
          label="Procedure"
          name="procedureId"
          type="autocomplete"
          [apiService]="procedureLookup"
          class="form-span-6"
        >
        </lib-form-field>
        <lib-form-field
          [parent]="entryForm"
          label="Modifier"
          name="modifierId"
          type="select"
          [apiService]="modifierLookup"
          class="form-span-6"
          [clearButton]="true"
        >
        </lib-form-field>
        <lib-form-field [parent]="entryForm" label="Amount" name="amount" type="money" class="form-span-4">
        </lib-form-field>
        <lib-form-field
          [parent]="entryForm"
          label="Effective From"
          name="effectiveFrom"
          type="date"
          class="form-span-4"
        >
        </lib-form-field>
        <mat-error class="from-error" *ngIf="invalidFromDate">From date must be before to.</mat-error>
        <lib-form-field [parent]="entryForm" label="Effective To" name="effectiveTo" type="date" class="form-span-4">
        </lib-form-field>
        <mat-error class="to-error" *ngIf="invalidToDate">To date must be after from.</mat-error>
      </lib-form-group>
    </div>
  </main>
  <div mat-dialog-actions class="buttons">
    <button mat-stroked-button mat-dialog-close *ngIf="viewOnly">Close</button>
    <button class="cancel" mat-stroked-button (click)="cancel()" *ngIf="!viewOnly">Cancel</button>
    <button
      class="save-button"
      mat-flat-button
      color="primary"
      (click)="save()"
      [disabled]="entryForm.invalid || invalidToDate"
      *ngIf="!viewOnly"
    >
      Save
    </button>
  </div>
</div>
