import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '@core/services/notification.service';
import { UserMaintenanceSecurityWrapperService } from '@core/services/service-wrappers/user-maintenance-security-wrapper.service';
import { compare } from 'fast-json-patch';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { first, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-role-dialog',
  templateUrl: './add-edit-role-dialog.component.html',
  styleUrls: ['./add-edit-role-dialog.component.scss']
})
export class AddEditRoleDialogComponent implements OnInit {

  formGroup = new UntypedFormGroup({
    roleName: new UntypedFormControl(null, Validators.required),
    description: new UntypedFormControl(null, Validators.required),
  });
  saving = false;
  viewOnly = false;
  permissionsArray = []
  roleId = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddEditRoleDialogComponent>,
    private userMaintenanceSecurityService: UserMaintenanceSecurityWrapperService,
    private notificationService: NotificationService
  ) {
    this.permissionsArray = [
      {label: 'Accounts', name: 'Accounts', value: 'None'}, 
      {label: 'Dashboard', name: 'Dashboard', value: 'None'}, 
      {label: 'Documents', name: 'Documents', value: 'None'},
      {label: 'Queues', name: 'Queues', value: 'None'}, 
      {label: 'Reports', name: 'Reports', value: 'None'}, 
      {label: 'Rules', name: 'Rules', value: 'None'}, 
      {label: 'Settings', name: 'Settings', value: 'None'}, 
      {label: 'System Maintenance', name: 'SystemMaintenance', value: 'None'}, 
      {label: 'Transactions', name: 'Transactions', value: 'None'}, 
      {label: 'Users', name: 'Users', value: 'None'}, 
      {label: 'Write-Offs', name: 'WriteOffs', value: 'None'}]

    this.permissionsArray?.forEach((control) => {
      this.formGroup.addControl(control.name, new UntypedFormControl('None'))
    })
    this.roleId = data?.roleId;
    this.viewOnly = this.data?.viewOnly;
    if(this.roleId) {
      this.userMaintenanceSecurityService.apiV1RoleIdGet(this.roleId)
      .pipe(first())
        .subscribe((result) => {
          result.rolePermissions.map((permission) => this.formGroup.controls[permission.permissionType].patchValue(permission.accessType ));
          this.formGroup.patchValue({
            roleName: result.roleName,
            description: result.description,
          })
        });
    }
  }

  ngOnInit(): void { }

  save() {
    if (this.formGroup.valid) {
      this.saving = true;
      let saveObservable: Observable<any>;
      let permissionsData = [];
      for(const permission in this.formGroup.value) {
        if (permission !== 'roleName' && permission !== 'description'){
          const ele = {permissionType: permission, accessType: this.formGroup.value[permission] };
          permissionsData.push(ele);
        }
      }
      const formData = {
        id: '',
        roleName: this.formGroup.value.roleName,
        description: this.formGroup.value.description,
        rolePermissions: permissionsData
      };
      let notification;
      if(this.roleId) {
        formData.id = this.roleId;
        notification = 'Role Updated';
        saveObservable = this.userMaintenanceSecurityService.apiV1RoleUpdateRolePost(formData).pipe(map((x: any) => x));
      } else {
        notification = 'Role Added';
        saveObservable = this.userMaintenanceSecurityService.apiV1RoleCreateRolePost(formData).pipe(map((x: any) => x));
      }

      saveObservable
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.dialogRef.close(true);
            this.notificationService.success(notification);
          },
          (err) => this.notificationService.error('Error Saving Role')
        )
        .add(() => {
          this.saving = false;
        });
    }
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }
}
