import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroupDefinition } from 'components';
import { compare } from 'fast-json-patch';
import { Observable } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DialogService } from '../../../../core/services/dialog.service';
import { CodeWrapperService } from 'src/app/core/services/service-wrappers/code-wrapper.service';
import { RemarkCodesLookupService } from '../../../../core/services/lookup/remark-codes-lookup.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-remark-codes-dialog',
  templateUrl: './remark-codes-dialog.component.html',
  styleUrls: ['./remark-codes-dialog.component.scss'],
  providers: [DatePipe]
})
export class RemarkCodesDialogComponent implements OnInit {
  formGroup = new UntypedFormGroup({});
  formInitialized = false;
  saving = false;
  sameCode = false;
  duplicateCode = false;
  CODE_TYPE = 'RemarkCode';
  remarkCodeId;
  localResults;
  viewOnly = false;

  formDefinitions: FormGroupDefinition[];
  getFormDefinitions(isEdit: boolean): FormGroupDefinition[] {
    return [
      {
        hideTitle: true,
        controls: [
          {
            label: 'Code',
            name: 'code',
            isReadOnly: isEdit,
            type: 'text',
            class: 'form-span-4',
            validators: Validators.required,
            selectionChanged: (event) => {
              this.nameChange(event);
            }
          },
          {
            label: 'Active',
            name: 'active',
            type: 'checkbox',
            initial: true,
            class: 'form-span-8',
            validators: Validators.required
          },
          {
            label: 'Description',
            name: 'name',
            type: 'text',
            class: 'form-span-12',
            validators: Validators.required
          },
          {
            label: 'Suppress Secondary Billing',
            name: 'suppressSecondary',
            type: 'checkbox',
            initial: false,
            class: 'form-span-6'
          },
          {
            label: 'Write Off Remainder Of Procedure',
            name: 'writeoffRemainder',
            type: 'checkbox',
            initial: false,
            class: 'form-span-6'
          }
        ]
      }
    ];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<RemarkCodesDialogComponent>,
    private service: CodeWrapperService,
    private dialogService: DialogService,
    public lookup: RemarkCodesLookupService,
    public datePipe: DatePipe,
    public dialog: MatDialog,
  ) {
    this.remarkCodeId = data?.remarkCodeId;
    const isEdit = this.remarkCodeId && this.remarkCodeId !== '';
    this.viewOnly = data?.viewOnly;
    this.formDefinitions = this.getFormDefinitions(isEdit);
  }

  ngOnInit(): void {
    this.setupForm();

    if (this.remarkCodeId && this.remarkCodeId !== '') {
      this.service
        .apiV1CodeCodetypeIdGet(this.CODE_TYPE, this.remarkCodeId)
        .pipe(first())
        .subscribe((result) => {
          this.formGroup.patchValue(result);
          this.formGroup.get('active').patchValue(!result.inactive);
        });
    }
  }

  setupForm() {
    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });
    this.formInitialized = true;
  }

  //throws error if name already exists
  nameChange(event) {
    if (event !== '') {
      //searches lookup service to query backend for current user name field input this is not exact match searhc so it brings back results that include the current user field input
      this.lookup.search(event).subscribe((result: any[]) => {
        this.localResults = result;

        //this result.find searches the results array from the lookup search to see if theres an exact match to the current user input
        let duplicate = result.find((o) => {
         return (o.value?.code.toLowerCase() === event.toLowerCase() && this.remarkCodeId !== o.value.id)
        })
        if (duplicate !== undefined) {
          this.sameCode = true;
          this.duplicateCode = true;
        }
        else {
          this.sameCode = false;
          this.duplicateCode = false;
        }
      });
    }
  }

  save() {
    if (this.formGroup.valid && this.saving === false) {
      this.saving = true;

      const formData = {
        code: this.formGroup.get('code').value,
        name: this.formGroup.get('name').value,
        inactive: this.formGroup.get('active').value === true ? false : true,
        suppressSecondary: this.formGroup.get('suppressSecondary').value,
        writeoffRemainder: this.formGroup.get('writeoffRemainder').value
      };

      let saveObservable: Observable<any>;
      let notification;
      if (this.remarkCodeId) {
        notification = 'Remark Code Updated';
        saveObservable = this.service
          .apiV1CodeCodetypePatchIdPatch(this.CODE_TYPE, this.remarkCodeId, compare({}, formData))
          .pipe(map((x: any) => x));
      } else {
        notification = 'Remark Code Added';
        saveObservable = this.service.apiV1CodeCodetypeAddPost(this.CODE_TYPE, formData).pipe(map((x: any) => x));
      }

      saveObservable
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.dialogRef.close(true);
            this.notificationService.success(notification);
          },
          (err) => this.notificationService.error('Error Saving Remark Code')
        )
        .add(() => {
          this.saving = false;
        });
    }
  }
}
