import { Injectable } from '@angular/core';
import { BaseGridSource, PagedModel } from 'components';
import { of } from 'rxjs';
import { catchError, finalize, map, take } from 'rxjs/operators';
import { FeeScheduleWrapperService } from 'src/app/core/services/service-wrappers/fee-schedule-wrapper.service';

@Injectable()
export class FeeSchedulesDialogService extends BaseGridSource<any> {
    feeScheduleId = '';
    savedPagedModel: any;
    constructor(private service: FeeScheduleWrapperService, feeScheduleId) {
        super();
        if (feeScheduleId !== undefined) {
            this.feeScheduleId = feeScheduleId
        }
    }

    reload() {
        this.load(this.savedPagedModel);
    }

    load(pagedModel: PagedModel) {
        this.loadingSubject.next(true);
        this.service
            .apiV1FeeScheduleEntriesIdPost(this.feeScheduleId, pagedModel)
            .pipe(
                map((response) => {
                    this.itemCountSubject.next(response.metaData.totalItemCount);
                    const data = response.data.map((feeSchedule) => {
                        return {
                            ...feeSchedule
                        };
                    });
                    this.savedPagedModel = pagedModel;
                    return { ...response, data };
                }),
                catchError(() => of({ data: [] })),
                finalize(() => {
                    this.loadingSubject.next(false);
                }),
                take(1)
            )
            .subscribe((data) => this.dataSourceSubject.next(data.data));
    }
}
