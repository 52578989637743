import { Component, OnInit, OnDestroy, ViewChild, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { SidebarNavConfig } from 'components/core/sidebar';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUsersPicture } from '../../state/auth-state/auth.selectors';
import { routeAnimations } from '../../animations/route.animations';
import { AppState } from '../../core.state';
import { DialogContent, PageLayoutComponent } from 'components';
import { selectAll as selectClients, getSelectedDataSetId } from '../../state/data-set/data-set.selectors';
import { filter, map } from 'rxjs/operators';
import { DataSet } from 'components/core/sidebar/data-set-selector';
import { selectDataSet } from '../../state/data-set/data-set.actions';
import { selectAll as selectAllReminders } from '../../state/reminders/reminders.selectors';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '@core/services/dialog.service';
import { selectImplementation } from 'src/app/features/implementation/state/implementation.selectors';
import { ImplementationViewModel, UserDetailsViewModel } from 'data';
import { ImplementationWrapperService } from '@core/services/service-wrappers/implementation-wrapper.service';
import { Doccument360WrapperService } from '../../services/service-wrappers/doccument-360-wrapper.service';
import { AppComponent } from 'src/app/app.component';
import { SessionStorageService } from '@core/services/session-storage.service';

@Component({
  selector: 'app-authorized',
  templateUrl: './authorized.component.html',
  styleUrls: ['./authorized.component.scss'],
  animations: [routeAnimations]
})
export class AuthorizedComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild('pageLayout') pageLayout: PageLayoutComponent;

  constructor(
    public store: Store<AppState>,
    private app: AppComponent,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private implementationService: ImplementationWrapperService,
    private document360Service: Doccument360WrapperService,
    private sessionStorageService: SessionStorageService,
    private router: Router
  ) { }

  mainNavConfig: SidebarNavConfig[] = [];

  selectImplementation$ = this.store.select(selectImplementation);
  implementation$: Subscription;
  implementationViewModel: ImplementationViewModel = null;

  sidenavType: string;
  userInfo: UserDetailsViewModel;

  userPicture = this.store.select(selectUsersPicture);
  notificationCount$ = this.store.select(selectAllReminders).pipe(
    map((reminders) => {
      const today = new Date();
      return reminders.filter((reminder) => new Date(reminder.scheduledDate) < today).length;
    })
  );

  dataSets$: Observable<DataSet[]> = this.store.select(selectClients).pipe(
    filter((clients) => !!clients),
    map((clients) => {
      return clients.map((client) => ({
        id: client.id,
        name: client.name,
        description: '',
        acronym: client.acronym,
      }));
    })
  );

  selectedDataSet$: Observable<DataSet> = combineLatest([this.dataSets$, this.store.select(getSelectedDataSetId)]).pipe(
    filter(([dataSets, selectedId]) => !!dataSets && !!selectedId),
    map(([dataSets, selectedId]) => {
      return dataSets?.find((x) => x.id === selectedId);
    })
  );

  ngOnInit(): void {
    this.implementationService.apiV1ImplementationGet().subscribe(
      (i) => {
        this.implementationViewModel = i;
        if (i?.status === 'Complete') this.loadMenu();
        this.implementation$ = this.selectImplementation$.subscribe((i) => { if (i != null) this.implementationViewModel = i; })
      });
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.implementation$?.unsubscribe();
  }

  openSideBarRight(type) {
    this.sidenavType = type;
    this.pageLayout.openSideNavRight();
  }

  closeSideBarRight() {
    this.pageLayout.closeSideNavRight();
  }

  documentClicked() {
    this.document360Service.apiV1Doc360Launch360Get().subscribe((callback) => {
      window.open(callback)
    })
  }

  newClientSelected(event) {
    this.closeSideBarRight();
    this.store.dispatch(selectDataSet({ dataSetId: event }));
  }

  /**
   * Loads menu items. Will be dynamic in the future.
   *
   * @memberof AuthorizedComponent
   */
  loadMenu() {
    this.userInfo = this.sessionStorageService.getItem("user-info");
    var docsActive = this.sessionStorageService.getItem("documents-active");
    var permissionValue = this.userInfo?.userPermissions?.find(x => x.permissionType == "Dashboard");
    if (permissionValue?.accessType === "View" || permissionValue?.accessType === "Update") {
      this.mainNavConfig.push({
        title: 'Dashboard',
        icon: 'dashboard',
        routerLink: './dashboard',
        exactMatchLink: true,
        disabled: true
      });
    }
    permissionValue = this.userInfo?.userPermissions?.find(x => x.permissionType == "Accounts");
    if (permissionValue?.accessType === "View" || permissionValue?.accessType === "Update") {
      this.mainNavConfig.push({
        title: 'Accounts',
        icon: 'account_circle',
        routerLink: './accounts'
      });
    }
    permissionValue = this.userInfo?.userPermissions?.find(x => x.permissionType == "Queues");
    if (permissionValue?.accessType === "View" || permissionValue?.accessType === "Update") {
      this.mainNavConfig.push({
        title: 'Queues',
        icon: 'folder_shared',
        routerLink: './queues'
      });
    }
    permissionValue = this.userInfo?.userPermissions?.find(x => x.permissionType == "Transactions");
    if (permissionValue?.accessType === "View" || permissionValue?.accessType === "Update") {
      this.mainNavConfig.push({
        title: 'Transactions',
        icon: 'attach_money',
        routerLink: './transactions',
        disabled: true
      });
    }
    permissionValue = this.userInfo?.userPermissions?.find(x => x.permissionType == "Reports");
    if (permissionValue?.accessType === "View" || permissionValue?.accessType === "Update") {
      this.mainNavConfig.push({
        title: 'Reports',
        icon: 'assessment',
        routerLink: './reports'
      });
    }
    if (docsActive) {
      permissionValue = this.userInfo?.userPermissions?.find(x => x.permissionType == "Documents");
      if (permissionValue?.accessType === "View" || permissionValue?.accessType === "Update") {
        this.mainNavConfig.push({
          title: 'Documents',
          icon: 'file_copy',
          routerLink: './documents'
        });
      }
    }
    permissionValue = this.userInfo?.userPermissions?.find(x => x.permissionType == "SystemMaintenance");
    if (permissionValue?.accessType === "View" || permissionValue?.accessType === "Update") {
      this.mainNavConfig.push({
        title: 'System Maintenance',
        icon: 'folder',
        routerLink: './files'
      });
    }
    permissionValue = this.userInfo?.userPermissions?.find(x => x.permissionType == "Settings");
    if (permissionValue?.accessType === "View" || permissionValue?.accessType === "Update") {
      this.mainNavConfig.push({
        title: 'Settings',
        icon: 'settings',
        routerLink: './settings',
        disabled: true
      });
    }
  }

  updatePassword() {
    const modalContent: DialogContent = {
      header: 'Update Password',
      body: 'Are you sure you want to update your password? You will be logged out. Click forgot password to initiate password reset.',
      cancelButtonText: 'Cancel',
      OKButtonText: 'Confirm'
    };
    this.dialogService.showConfirm(modalContent).subscribe((result) => {
      if (result) {
        this.app.logout();
      }
    })
  }

  logOut() {
    this.router.navigateByUrl('/logout');
  }
}
