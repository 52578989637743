import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '@core/services/dialog.service';
import { NotificationService } from '@core/services/notification.service';
import { UserMaintenanceSecurityWrapperService } from '@core/services/service-wrappers/user-maintenance-security-wrapper.service';
import { DialogContent } from 'components';
import { Display } from 'html2canvas/dist/types/css/property-descriptors/display';
import { map, take } from 'rxjs/operators';
import { AddEditRoleDialogComponent } from './add-edit-role-dialog/add-edit-role-dialog.component';
import { SessionStorageService } from '@core/services/session-storage.service';
import { ImplementationDetailViewModel, ImplementationViewModel, UserDetailsViewModel } from 'data';
import { ImplementationWrapperService } from '@core/services/service-wrappers/implementation-wrapper.service';
import { Subscription, Observable } from 'rxjs';
import { selectImplementation } from 'src/app/features/implementation/state/implementation.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ImplementationState } from 'src/app/features/implementation/state/implementation.state';
import * as ImplementationActions from '../../../implementation/state/implementation.actions';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  displayedColumns: string[] = [];
  rolesList = [];
  loading = false;
  error = false;
  roleListError = "";
  public length: number = 0;
  permissionsList: {display: string, type: string}[];
  permissionTypeList: string[];
  userInfo: UserDetailsViewModel;
  viewOnly = false;

  overflowMenuOptions = [
    {
      id: 'edit',
      label: 'Edit Role'
    },
    {
      id: 'delete',
      label: 'Delete Role'
    }
  ]
  overflowViewOnly = [
    {
      id: 'view',
      label: 'View Role'
    },
  ]

  constructor(
    private dialogService: DialogService,
    public dialog: MatDialog,
    private userMaintenanceSecurityService: UserMaintenanceSecurityWrapperService,
    private implementationService: ImplementationWrapperService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private store: Store<ImplementationState>
    ) { }


  ngOnInit(): void {
    this.userInfo = this.sessionStorageService.getItem("user-info");
    var permissionValue = this.userInfo?.userPermissions?.find(x => x.permissionType == "Users");
    if (permissionValue?.accessType !== "Update") {
      this.overflowMenuOptions = this.overflowViewOnly;
      this.viewOnly = true;
    }
    this.getRoleList();
    this.implementationSubscribe();
   }

   getRoleList(): void {
    this.loading = true;
    this.userMaintenanceSecurityService
    .apiV1RolePost({
      pageSize: 100
    }).subscribe((response) => {
      this.loading = false;
      this.error = false;
      this.permissionsList = [
        {display: 'Accounts', type: 'Accounts'}, 
        {display: 'Dashboard', type: 'Dashboard'},
        {display: 'Documents', type: 'Documents'},
        {display: 'Queues', type: 'Queues'},
        {display: 'Reports', type: 'Reports'},
        {display: 'Rules', type: 'Rules'},
        {display: 'Settings', type: 'Settings'},
        {display: 'System Maintenance', type: 'SystemMaintenance'},
        {display: 'Transactions', type: 'Transactions'},
        {display: 'Users', type: 'Users'},
        {display: 'Write-Offs', type: 'WriteOffs'}];
      this.permissionTypeList = ['Accounts', 'Dashboard', 'Documents', 'Queues', 'Reports', 'Rules', 'Settings',
                'System Maintenance', 'Transactions', 'Users', 'Write-Offs']
      this.displayedColumns = ['action', 'roleName', ...this.permissionTypeList];
      this.rolesList = response.data.map((role) => {
        return {
          id: role.id,
          roleName: role.roleName,
          updatePermissions: role.rolePermissions.map((permission) => permission.accessType === "Update" ? this.getDisplayedName(permission.permissionType) : null),
          viewPermissions: role.rolePermissions.map((permission) => permission.accessType === "View" ? this.getDisplayedName(permission.permissionType) : null)
        }
      });
      this.length = response.metaData.totalItemCount;
    }, (error) => {
      this.loading = false;
      this.error = true;
        
      if (error.status === 403) {
        this.roleListError = "Current user is not authorized to view Roles."
      }
      else {
        this.roleListError = "Error while accessing roles.  Please contact support."
      }
    })
   }

  getDisplayedName(roleType: string): string {
    let item = this.permissionsList.find(x => x.type === roleType);
    return item.display;
  }

  overflowMenuClicked(value, data) {
    if (value === 'delete') {
      this.openDeleteRoleModal(data.id);
    } else if (value === 'edit') {
      this.addEditRole(data.id);
    } else if (value === 'view') {
      this.addEditRole(data, true);
    }
  }

  openDeleteRoleModal(roleId: string) {
    const modalContent: DialogContent = {
      header: '',
      body: `Are you sure you want to delete this role?`,
      cancelButtonText: 'Back',
      OKButtonText: 'Confirm'
    };
    this.dialogService.showConfirmDialog(modalContent).subscribe((result) => {
      if (result) {
        this.userMaintenanceSecurityService
          .apiV1RoleDeleteRoleDelete(roleId)
          .pipe(
            map((x: any) => x),
            take(1)
          )
          .subscribe((deleteResult) => {
            this.notificationService.success('Role Deleted');
            this.getRoleList();
          });
      }
    });
  }

  addEditRole(roleId = null, viewOnly = false) {
    this.openAddEditRoleModal(roleId, viewOnly);
  }

  openAddEditRoleModal(roleId: string, viewOnly = false) {
    const dialog = this.dialog.open(AddEditRoleDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { permissions: this.permissionTypeList, roleId, viewOnly },
      height:'100%'
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.getRoleList();
      }
    });
  }

  public isImplementation: boolean = false;
  public gonext: boolean = false;
  public itemCount: number = 0;
  private activatedRouteSub: Subscription;
  private implementation$: Observable<ImplementationViewModel> = this.store.select(selectImplementation);;
  private implementationSub: Subscription;
  private implementation : ImplementationViewModel = null;
  private implementationDetail: ImplementationDetailViewModel = null;
  private nextImplementationDetail: ImplementationDetailViewModel = null;  
  private STEP_CODE = 'roles'

  implementationSubscribe() {
    this.activatedRouteSub = this.activatedRoute.data.subscribe(data => {
      this.isImplementation = data.implementation;

      if (this.isImplementation) {
        this.implementationSub =
          this.implementation$.subscribe((i) => {
            this.implementation = i;
            this.implementationDetail = this.implementation.details.find(d => d.implementationStepCode == this.STEP_CODE);
            this.nextImplementationDetail = this.implementation.details.find(d => d.order == this.implementationDetail.order + 1);
        });
      }
    });
    this.store.dispatch(ImplementationActions.updateImplementationStep({ stepCode: this.STEP_CODE}));
  }
  
  implementationUnsubscribe() {
    if (this.isImplementation) {
      this.activatedRouteSub.unsubscribe();
      this.implementationSub.unsubscribe();
    }
  }

  next(): void {
    if (this.implementationDetail.status == "InProgress") {
      this.gonext = true;
      this.implementationService
      .apiV1ImplementationCompleteStepCodePost(this.STEP_CODE)
      .subscribe(
        () => { 
          this.gonext = false;  
          this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
        },
        () => this.gonext = false
      );
    }
    else {
      this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
    }
  }

  ngOnDestroy(): void {
    this.implementationUnsubscribe();
  }


  trackByIndex(index: number, item: any): number {
    return index;
  }
}
