import { Component, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  BodyClasses,
  ColumnDefinition,
  ColumnDisplayType,
  ColumnSizes,
  GridComponent,
  GridConfiguration,
  OrderByPipe,
  DialogContent,
  FilterType
} from 'components';
import { map, take } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog.service';
import { InsuranceCarriersDialogComponent } from './insurance-carriers-dialog/insurance-carriers-dialog.component';
import { InsuranceCarriersService } from './insurance-carriers.service';
import { InsuranceCarrierWrapperService } from 'src/app/core/services/service-wrappers/insurance-carrier-wrapper.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ImplementationWrapperService } from '@core/services/service-wrappers/implementation-wrapper.service';
import { Store } from '@ngrx/store';
import { ImplementationState } from '../../implementation/state/implementation.state';
import { Observable, Subscription } from 'rxjs';
import { selectImplementation } from '../../implementation/state/implementation.selectors';
import * as ImplementationActions from '../../implementation/state/implementation.actions';
import { ImplementationDetailViewModel } from 'projects/data/src/lib/model/implementationDetailViewModel';
import { ImplementationViewModel } from 'projects/data/src/lib/model/implementationViewModel';
import { UploadCodesComponent } from '../upload-codes/upload-codes.component';
import { SessionStorageService } from '@core/services/session-storage.service';
import { UserDetailsViewModel } from 'data';

@Component({
  selector: 'app-insurance-carriers',
  templateUrl: './insurance-carriers.component.html',
  styleUrls: ['./insurance-carriers.component.scss'],
  providers: [InsuranceCarriersService]
})
export class InsuranceCarriersComponent implements OnInit, OnDestroy {
  @ViewChild(GridComponent) grid: GridComponent;
  orderPipe: OrderByPipe = new OrderByPipe();
  activeFilter: string = 'active';
  userInfo: UserDetailsViewModel;
  viewOnly = false;
  viewOnlyOptions = [{ id: 'view', label: 'View' }];
  overflowMenuOptions = [
    {
      id: 'edit',
      label: 'Edit'
    }
  ];
  private columnDefinitions: ColumnDefinition[] = [
    {
      id: 'actions',
      displayKey: 'actions',
      displayType: ColumnDisplayType.actionList,
      columnSize: ColumnSizes.tiny,
      bodyClasses: [BodyClasses.alignRight],
      displayOrder: 0,
      headerText: '',
      overflowMenuOptions: this.overflowMenuOptions,
      overflowMenuClicked: (value, data) => {
        if (value === 'delete') {
          this.deleteEntry(data);
        } else if (value === 'edit') {
          this.openInsuranceCarriersModal(data.id);
        } else if (value === 'view') {
          this.openInsuranceCarriersModal(data.id, true);
        }
      }
    },
    {
      id: 'carrierCode',
      displayKey: 'carrierCode',
      headerText: 'Carrier Code',
      displayOrder: 1,
      columnSize: ColumnSizes.extraNarrow,
      filterable: true,
      sortable: true,
      sticky: true
    },
    {
      id: 'insuranceName',
      displayKey: 'carrierName',
      headerText: 'Insurance Name',
      displayOrder: 2,
      columnSize: ColumnSizes.normal,
      sortable: true,
      filterable: true
    },
    {
      id: 'address',
      displayKey: 'address1',
      headerText: 'Address',
      displayOrder: 3,
      bodyClasses: [BodyClasses.alignLeft],
      columnSize: ColumnSizes.normal,
      sortable: true,
      filterable: true
    },
    {
      id: 'city',
      displayKey: 'city',
      headerText: 'City',
      displayOrder: 4,
      bodyClasses: [BodyClasses.alignLeft],
      columnSize: ColumnSizes.midwidth,
      sortable: true,
      filterable: true
    },
    {
      id: 'state',
      displayKey: 'stateCode',
      headerText: 'State',
      displayOrder: 5,
      bodyClasses: [BodyClasses.alignLeft],
      columnSize: ColumnSizes.extraNarrow,
      sortable: true,
      filterable: true
    },
    {
      id: 'zip',
      displayKey: 'zip',
      headerText: 'Zip',
      displayOrder: 6,
      bodyClasses: [BodyClasses.alignRight],
      columnSize: ColumnSizes.extraNarrow,
      sortable: true,
      filterable: true
    },
    {
      id: 'PayerId',
      displayKey: 'payerId',
      headerText: 'Payer ID',
      displayOrder: 7,
      bodyClasses: [BodyClasses.alignLeft],
      columnSize: ColumnSizes.extraNarrow,
      sortable: true,
      filterable: true
    }
  ];
  gridConfig: GridConfiguration = {
    columnDefinitions: this.columnDefinitions,
    displayColumns: this.orderPipe.transform(this.columnDefinitions, 'displayOrder').map((x) => x.id)
  };

  dataSource: InsuranceCarriersService;
  constructor(
    private insuranceCarriersService: InsuranceCarriersService,
    public dialog: MatDialog,
    private deleteService: InsuranceCarrierWrapperService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private implementationService: ImplementationWrapperService,
    private router: Router,
    private store: Store<ImplementationState>, 
    private sessionStorageService: SessionStorageService
  ) {
    this.userInfo = this.sessionStorageService.getItem("user-info");
    this.viewOnly = this.userInfo?.userPermissions?.find(x => x.permissionType == "SystemMaintenance")?.accessType !== "Update";
    if (this.viewOnly) {
      this.columnDefinitions[0].overflowMenuOptions = this.viewOnlyOptions;
    }
    this.dataSource = insuranceCarriersService;
    this.dataSource.activeFilter = 'active';
  }

  addInsuranceCarrier() {
    this.openInsuranceCarriersModal(null);
  }

  openInsuranceCarriersModal(insuranceCarrierId: string, viewOnly = false) {
    const dialog = this.dialog.open(InsuranceCarriersDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { insuranceCarrierId, viewOnly }
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.grid.loadData();
      }
    });
  }

  deleteEntry(data) {
    const modalContent: DialogContent = {
      header: 'Delete Insurance Carrier',
      body: `Are you sure you want to delete "${data.carrierName}"? This cannot be undone.`,
      cancelButtonText: 'Cancel',
      OKButtonText: 'Delete'
    };
    this.dialogService.showConfirm(modalContent).subscribe((result) => {
      if (result) {
        this.deleteService
          .apiV1InsuranceCarrierIdDelete(data.id)
          .pipe(
            map((x: any) => x),
            take(1)
          )
          .subscribe((deleteResult) => {
            this.grid.loadData();
            this.notificationService.notifyNow("Insurance Carrier Deleted");
          });
      }
    });
  }
  uploadCodes() {
    const dialog = this.dialog.open(UploadCodesComponent, {
      disableClose: true,
      autoFocus: false,
      height: '600px',
      width:'600px',
      data: {code: 'insurance-carrier'}
    });

    dialog.afterClosed().subscribe((result) => {
        this.grid.loadData();
    });
  }

  public isImplementation: boolean = false;
  public gonext: boolean = false;
  public itemCount: number = 0;
  private activatedRouteSub: Subscription;
  private itemCountSub: Subscription;
  private implementation$: Observable<ImplementationViewModel> = this.store.select(selectImplementation);;
  private implementationSub: Subscription;
  private implementation : ImplementationViewModel = null;
  private implementationDetail: ImplementationDetailViewModel = null;
  private nextImplementationDetail: ImplementationDetailViewModel = null;  
  private STEP_CODE = 'insurance-carriers'

  implementationSubscribe() {
    this.activatedRouteSub = this.activatedRoute.data.subscribe(data => {
      this.isImplementation = data.implementation;

      if (this.isImplementation) {
        this.itemCountSub = this.insuranceCarriersService.itemCountSubject.subscribe((c) => this.itemCount = c);
        this.implementationSub =
          this.implementation$.subscribe((i) => {
            this.implementation = i;
            this.implementationDetail = this.implementation.details.find(d => d.implementationStepCode == this.STEP_CODE);
            this.nextImplementationDetail = this.implementation.details.find(d => d.order == this.implementationDetail.order + 1);
        });
      }
    });
    this.store.dispatch(ImplementationActions.updateImplementationStep({ stepCode: this.STEP_CODE}));
  }
  
  implementationUnsubscribe() {
    if (this.isImplementation) {
      this.activatedRouteSub.unsubscribe();
      this.itemCountSub.unsubscribe();
      this.implementationSub.unsubscribe();
    }
  }

  next(): void {
    if (this.implementationDetail.status == "InProgress") {
      this.gonext = true;
      this.implementationService
      .apiV1ImplementationCompleteStepCodePost(this.STEP_CODE)
      .subscribe(
        () => { 
          this.gonext = false;  
          this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
        },
        () => this.gonext = false
      );
    }
    else {
      this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
    }
  }

  ngOnInit(): void {
    this.implementationSubscribe();
  }
  ngOnDestroy(): void {
    this.implementationUnsubscribe();
  }
  showInactiveChanges(event) {
    this.activeFilter = event.checked ? 'active' : 'inactive';
    this.dataSource.activeFilter = this.activeFilter;
    this.grid.loadData();
  }
}
