/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DeniedChargesViewModel {
  visitId?: string;
  patientId?: string;
  arTransactionId?: string | null;
  accountNumber?: number;
  visit?: number;
  fullName?: string | null;
  procedureCode?: string | null;
  procedureDescription?: string | null;
  balance?: number;
  serviceDate?: string;
  insuranceCarrierNumber?: string | null;
  insuranceCarrierName?: string | null;
  assignDate?: string;
  eobCode?: string | null;
  eobId?: string | null;
  arTransactionDetailEobCodeId?: string | null;
  insuranceId?: string | null;
  readonly queue?: string | null;
  readonly iconType?: string | null;
  readonly resultDescription?: string | null;
  readonly additionalDetails?: Array<string> | null;
}
