import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroupDefinition } from 'components';
import { compare } from 'fast-json-patch';
import { Observable, of } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { CodeWrapperService } from 'src/app/core/services/service-wrappers/code-wrapper.service';

@Component({
  selector: 'app-payment-codes-dialog',
  templateUrl: './payment-codes-dialog.component.html',
  styleUrls: ['./payment-codes-dialog.component.scss']
})
export class PaymentCodesDialogComponent implements OnInit {
  formGroup = new UntypedFormGroup({});
  formInitialized = false;
  saving = false;
  CODE_TYPE = 'PaymentCode';
  paymentCodeId;
  viewOnly = false;

  formDefinitions: FormGroupDefinition[];
  getFormDefinitions(isEdit: boolean): FormGroupDefinition[] {
    return [
      {
        hideTitle: true,
        controls: [
          {
            label: 'Code',
            name: 'code',
            isReadOnly: isEdit,
            type: 'text',
            class: 'form-span-10',
            validators: Validators.required
          },
          {
            label: 'Active',
            name: 'active',
            type: 'checkbox',
            class: 'form-span-2',
            initial: true,
            validators: Validators.required
          },
          {
            label: 'Description',
            name: 'name',
            type: 'text',
            class: 'form-span-12',
            validators: Validators.required
          }
        ]
      }
    ];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<PaymentCodesDialogComponent>,
    private service: CodeWrapperService
  ) {
    this.paymentCodeId = data?.paymentCodeId;
    const isEdit = this.paymentCodeId && this.paymentCodeId !== '';
    this.viewOnly = data?.viewOnly;
    this.formDefinitions = this.getFormDefinitions(isEdit);
  }

  ngOnInit(): void {
    this.setupForm();

    if (this.paymentCodeId && this.paymentCodeId !== '') {
      this.service
        .apiV1CodeCodetypeIdGet(this.CODE_TYPE, this.paymentCodeId)
        .pipe(first())
        .subscribe((result) => {
          this.formGroup.patchValue(result);
          this.formGroup.get('active').patchValue(!result.inactive);
        });
    }
  }

  setupForm() {
    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });
    this.formInitialized = true;
  }

  save() {
    if (this.formGroup.valid && this.saving === false) {
      this.saving = true;

      const formData = {
        code: this.formGroup.get('code').value,
        name: this.formGroup.get('name').value,
        inactive: this.formGroup.get('active').value === true ? false : true
      };

      let saveObservable: Observable<any>;
      let notification;
      if (this.paymentCodeId) {
        notification = 'Payment Code Updated';
        saveObservable = this.service
          .apiV1CodeCodetypePatchIdPatch(this.CODE_TYPE, this.paymentCodeId, compare({}, formData))
          .pipe(map((x: any) => x));
      } else {
        notification = 'Payment Code Added';
        saveObservable = this.service.apiV1CodeCodetypeAddPost(this.CODE_TYPE, formData).pipe(map((x: any) => x));
      }

      saveObservable
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.dialogRef.close(true);
            this.notificationService.success(notification);
          },
          (err) => this.notificationService.error('Saving Payment Code')
        )
        .add(() => {
          this.saving = false;
        });
    }
  }
}
