/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EligibilityErrorViewModel {
  visitId?: string;
  patientId?: string;
  insuranceId?: string | null;
  insuranceCarrierId?: string | null;
  accountNumber?: number;
  visit?: number;
  serviceDate?: string;
  balance?: number;
  insuranceCarrierNumber?: string | null;
  insuranceCarrierName?: string | null;
  responseDate?: string;
  benefitCode?: string | null;
  readonly benefit?: string | null;
  serviceTypeCode?: string | null;
  readonly serviceType?: string | null;
  rejectReasonCode?: string | null;
  readonly rejectReason?: string | null;
  followUpActionCode?: string | null;
  readonly followUpAction?: string | null;
  readonly responseType?: string | null;
  readonly responseDescription?: string | null;
  responseId?: string;
  patientName?: string | null;
  readonly queue?: string | null;
  readonly iconType?: string | null;
  readonly resultDescription?: string | null;
  readonly additionalDetails?: Array<string> | null;
}
