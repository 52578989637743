<div class="dialog-form report-form">
  <div class="dialog-form__close">
    <a (click)="cancel()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <header mat-dialog-title class="dialog-form__title">
    <h2>Fee Schedule</h2>
  </header>
  <main mat-dialog-content>
    <div class="dialog-form__container">
      <ng-container *ngIf="formInitialized">
        <lib-dynamic-form id="form" [dynamicFormGroup]="formGroup" [formGroupDefinitions]="formDefinitions">
        </lib-dynamic-form>
      </ng-container>
      <mat-error class="error" *ngIf="duplicateName">Fee Schedule name must be unique.</mat-error>
      <div mat-dialog-actions class="buttons" *ngIf="!allowAdd">
        <button class="cancel-button" mat-stroked-button mat-dialog-close>Cancel</button>
        <lib-button-loading
          color="primary"
          class="save-button"
          [loading]="saving"
          [invalid]="formGroup.invalid || this.duplicateName"
          (clickEvent)="save(false)"
        >
          Create
        </lib-button-loading>
      </div>
      <div class="actions">
        <div *ngIf="!viewOnly && allowAdd" class="copy-div">
          <button
            mat-button
            style="color: #18a0fb"
            [ngClass]="commit === true ? 'disabled' : 'populate'"
            [disabled]="commit"
            (click)="populateFees()"
          >
            <mat-icon>add_circle_outline</mat-icon>
            Copy From Existing
          </button>
        </div>
        <button
          class="add-div"
          (click)="addEntry()"
          mat-button
          color="primary"
          [disabled]="!allowAdd"
          *ngIf="!viewOnly"
        >
          <mat-icon>add_circle_outline</mat-icon> Add Fee Entry
        </button>
        <button
          [disabled]="!allowAdd"
          class="upload-div"
          mat-button
          color="primary"
          (click)="uploadFeeSchedule()"
          *ngIf="!viewOnly"
        >
          <mat-icon>file_upload</mat-icon> Upload Fee Schedule
        </button>
      </div>
      <div *ngIf="dataSource && gridConfig" class="mat-elevation-z8">
        <lib-grid [dataSource]="dataSource" [gridConfiguration]="gridConfig" skipLoadOnInit="true" loadAfterInit="true">
          <div class="">
            <h4>No Fee Schedule Entries Found.</h4>
          </div>
        </lib-grid>
      </div>
    </div>
  </main>
  <div mat-dialog-actions class="buttons" *ngIf="allowAdd">
    <button mat-stroked-button mat-dialog-close *ngIf="viewOnly">Close</button>
    <button class="cancel-edit-button" (click)="cancel()" mat-stroked-button *ngIf="!viewOnly">Cancel</button>
    <lib-button-loading
      *ngIf="!viewOnly"
      color="primary"
      class="save-button"
      [loading]="saving"
      [invalid]="formGroup.invalid || duplicateName === true"
      (clickEvent)="save(true)"
      >Save
    </lib-button-loading>
  </div>
</div>
