<div class="users__wrapper">
  <header class="sub-page-layout__header users__header">
    <div class="users__header-text h2 h2--semi-bold">User Maintenance</div>
    <button *ngIf="!error && !viewOnly" mat-flat-button color="primary" class="users__add-item" (click)="addEditUser()">
      <mat-icon>add_circle_outline</mat-icon> Add New User
    </button>
  </header>

  <div class="tableContainer mat-elevation-z2" *ngIf="usersList.length">
    <table mat-table [dataSource]="usersList" class="dataSets__grid">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }} {{ element.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="roles">
        <th mat-header-cell *matHeaderCellDef>Roles</th>
        <td mat-cell *matCellDef="let element">
          <div class="grid-chip-list">
            <span *ngFor="let chip of element.userRoles; trackBy: trackById" class="grid-chip">{{ chip.name }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="dataSets">
        <th mat-header-cell *matHeaderCellDef>Datasets</th>
        <td mat-cell *matCellDef="let element">
          <div class="grid-chip-list">
            <span *ngFor="let chip of element.userDatasets; trackBy: trackById" class="grid-chip">{{ chip.name }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <lib-overflow-menu [options]="overflowMenuOptions" (optionClicked)="overflowMenuClicked($event, element)">
          </lib-overflow-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div class="table-paginator" *ngIf="!refreshPaginator">
      <mat-paginator
        #paginator
        aria-label="datasource"
        [pageSize]="25"
        [pageSizeOptions]="[25, 50, 100, 250, 500]"
        [length]="length"
        (page)="handlePageEvent($event)"
      >
      </mat-paginator>
    </div>
  </div>
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="150"></mat-spinner>
  </div>
  <div class="users__error" *ngIf="error">
    {{ userListError }}
  </div>
</div>
<footer *ngIf="isImplementation && length > 0" class="users__footer">
  <lib-button-loading color="primary" class="users__button" [invalid]="length === 0" (clickEvent)="next()"
    >Next
  </lib-button-loading>
</footer>
