import { Injectable } from '@angular/core';
import {
  AddReminderViewModel,
  PagedModelRequest,
  ReminderDetailsViewModelPagedModelResponse,
  ReminderService
} from 'data';
import { BaseWrapperService } from './base-wrapper.service';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ReminderWrapperService extends BaseWrapperService {
  constructor(private service: ReminderService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1ReminderAllDetailPost(
    pagedModelRequest: PagedModelRequest
  ): Observable<ReminderDetailsViewModelPagedModelResponse> {
    return this.service.apiV1ReminderAllDetailPost(this.getDataSetId(), pagedModelRequest);
  }
  apiV1ReminderAllPost(pagedModelRequest: PagedModelRequest) {
    return this.service.apiV1ReminderAllPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1ReminderAddPost(addNoteViewModel: AddReminderViewModel) {
    return this.service.apiV1ReminderAddPost(this.getDataSetId(), addNoteViewModel);
  }

  apiV1ReminderIdDelete(id: string) {
    return this.service.apiV1ReminderIdDelete(id, this.getDataSetId());
  }

  apiV1ReminderDismissAllIdPost(id: string) {
    return this.service.apiV1ReminderDismissAllIdPost(id, this.getDataSetId());
  }
}
