import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectRemindersLoading, selectAll as selectAllReminders } from '../../state/reminders/reminders.selectors';
import { updateReminders } from '../../state/reminders/reminders.actions';
import { getSelectedDataSetId } from '../../state/data-set/data-set.selectors';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReminderWrapperService } from '@core/services/service-wrappers/reminder-wrapper.service';
import { take } from 'rxjs';
import { NotificationService } from '@core/services/notification.service';
import { selectUserId } from '@core/state/user/user.selectors';
import { DialogContent } from 'components';
import { DialogService } from '@core/services/dialog.service';
type ReminderView = 'list' | 'form';

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss']
})
export class RemindersComponent implements OnInit {
  disableCreateNote = false;
  reminderView: ReminderView = 'list';
  patientId;
  isDialog = false;
  userId;
  existingReminders;

  constructor(
    private store: Store<any>,
    @Optional() public dialogRef: MatDialogRef<RemindersComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private reminderService: ReminderWrapperService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
  ) {}
  areRemindersLoading = this.store.select(selectRemindersLoading);

  clientId$ = this.store.select(getSelectedDataSetId);
  ngOnInit(): void {
    this.store.select(selectUserId).subscribe((x) => {
      this.userId = x;
    });
    this.store.select(selectAllReminders).subscribe((x) => {
      this.existingReminders = x;
    });
    if (this.data) {
      this.patientId = this.data?.patientId;
      this.isDialog = true;
      this.showForm();
    }
  }
  showForm() {
    this.reminderView = 'form';
    this.disableCreateNote = true;
  }
  hideForm(event) {
    if (event === true) {
      this.store.dispatch(updateReminders());
    }

    this.reminderView = 'list';
    this.disableCreateNote = false;
  }

  dismissAll() {
    const modalContent: DialogContent = {
      header: '',
      body: `Are you sure you want to dismiss all reminders?`,
      cancelButtonText: 'Back',
      OKButtonText: 'Confirm'
    };
    const dialogRef = this.dialogService.showConfirmDialog(modalContent).subscribe((result) => {
      if (result) {
        this.reminderService
        .apiV1ReminderDismissAllIdPost(this.userId)
        .pipe(take(1))
        .subscribe(
          (x) => {
            this.store.dispatch(updateReminders());
          },
          (err) => this.notificationService.error('Error dismissing reminder')
        );
      }
    });
    
  }

  close() {
    this.dialogRef.close(false);
  }
}
