/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HoldStatus } from './holdStatus';
import { QueueResultDetailModel } from './queueResultDetailModel';

export interface QueueVisitResultsViewModel {
  id?: string;
  datasetId?: string;
  patientId?: string;
  referenceNumber?: number | null;
  adjusted?: number;
  adjusted2?: number;
  balance?: number;
  readonly patientBalance?: number;
  readonly insuranceBalance?: number;
  paid?: number;
  lastBilledDate?: string | null;
  serviceFromDate?: string | null;
  lastBilledInsurance?: string | null;
  isMerged?: boolean;
  holdStatus?: HoldStatus;
  queueResults?: Array<QueueResultDetailModel> | null;
}
